import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "react-i18next";

import { IPageData } from "interfaces";
import { Button, Layout, SectionTitle, SEO } from "components";

const accomodation = ({ data }: IPageData) => {
  const { t } = useTranslation();
  const promoImg = getImage(data.img.childImageSharp);

  return (
    <Layout>
      <SEO
        title={t`config.nav.accomodation`}
        description={t`informations.paragraph_1`}
      />
      <article className="accomodation container">
        <SectionTitle title={t`config.title.accomodation`} />
        <section>
          <div className="accomodation__flex">
            <div className="accomodation__text content-text">
              <p>{t("accomodation.paragraph_1")}</p>
              <p>{t("accomodation.paragraph_2")}</p>
              <Button
                className="accomodation__btn"
                text="config.button.readMore"
                type="outline"
                to={t`accomodation.link`}
                newTab
              />
              <div className="accomodation__hint content-text">
                <p>{t("accomodation.paragraph_3")}</p>
                <div className="accomodation__image-qr">
                  <StaticImage
                    alt="qr"
                    objectFit="contain"
                    placeholder="blurred"
                    src="../../images/accomodation/qr.jpg"
                  />
                </div>
              </div>
            </div>
            <div className="accomodation__image-right">
              {promoImg && (
                <a
                  href={t("accomodation.link")}
                  target="_blank"
                  className="publications__image-link"
                >
                  <GatsbyImage
                    className="publications__image"
                    alt={data.img.name}
                    image={promoImg}
                  />
                </a>
              )}
            </div>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default accomodation;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    img: file(relativeDirectory: { eq: "accomodation" }, name: { ne: "qr" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
      }
      name
      ext
    }
  }
`;
